import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
// import LevelSetting from './LevelSetting.vue';

// formCreate.component('LevelSetting', LevelSetting);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'main_form',
    };
  },
  async created() {
    await this.getFormRule('main_form');
  },
  methods: {
    // 设置规则
    setRule(item) {
      // console.log('item', item);
      if (item.field === 'beginTime') {
        Object.assign(item, {
          ...this.props,
          props: {
            type: 'datetime',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            relation: {
              beginField: 'beginTime',
              endField: 'endTime',
              gtNow: true,
            },
          },
        });
      } else if (item.field === 'endTime') {
        Object.assign(item, {
          ...this.props,
          props: {
            type: 'datetime',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            relation: {
              beginField: 'beginTime',
              endField: 'endTime',
            },
            defaultTime: '23:59:59',
          },
        });
      }
      return item;
    },
    // 渲染完成后执行
    formComplete() {
      const { type, id } = this.formConfig;

      if (type === 'edit' || type === 'view') {
        request.get('/cps/fxh/terminalConfigurationRuleController/findById', {
          id,
        }).then((res) => {
          if (res.success) {
            this.setValue(res.result);
          }
        });
      }
    },

    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        // 默认新增
        const url = '/cps/v1/fxhCycleConfig/save';
        // 有id  就是编辑
        console.log('🚀 ~ submit ~ this.formConfig.id:', this.formConfig.id);
        const params = {
          ...formData,
        };
        if (this.formConfig.id) {
          params.id = this.formConfig.id;
        }
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },

  },
};
